export const Nav = [
    {
        id: 1,
        desc: 'Home',
        path: 'home'
    },
    {
        id: 2,
        desc: 'Commercial',
        path: 'commercial'
    },
    {
        id: 3,
        desc: 'Residential',
        path: 'residential'
    },
    {
        id: 4,
        desc: 'Services',
        path: 'services'
    },
    {
        id: 5,
        desc: "About",
        path: 'about'
    }
]